.gutterButton {
    padding: 15px;
    font-size: 15px;
    border-radius: 0px;
    margin: 0 0;
    justify-content: flex-start;
    border-bottom: 1px solid #DCDCDC;
}

.gutterButton:first-child {
    border-top: 1px solid #DCDCDC;
}


.document-wrapper {
    display: flex;
    flex-direction: column;
    text-align: left;
}


.save-btn {
    border: solid 1px #232f3e;
    border-radius: 8px;
    color: #232f3e;
    padding: 6px 10px;
    background-color: white;
    display: flex;
    align-self: flex-start;
    margin-top: 8px;
}

.save-btn:hover {
    background-color: whitesmoke;
    color: #232f3e;
}

.pill-select {
    height: 35px;
    margin-right: 12px;
}

.fl-r {
    float: right;
}

.tag-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    button {
        border: solid 2px #232f3e;
        border-radius: 10px;
        margin: 10px 10px;
        padding: 15px;
        font-size: 11px;
        text-align: left;
        flex-grow: 1;
        width: 33%;
    }

    .filled {
        background-color: #232f3e;
        color: white;
    }
}



.document-sections-container {
    display: flex;
    flex-direction: column;
    margin-top: 58px;
}