.contentLibrary {
    display: flex;
    margin-top: 30px;
    padding: 0 calc((100vw - 100vw*0.9) / 2);
    justify-content: space-between;
}

.leftGutter {
    width: 18%;
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: white;
}

.gutterButton {
    margin: 10px 10px;
    padding: 15px!important;
    font-size: 15px;
    text-align: left;
}

.gutterButton:hover {
    background-color: whitesmoke;
}

.main {
    margin-left: 60px;
    width: 77%;
    margin-bottom: 5rem;
}

.createButton:hover {
    background-color: rgba(35,47,62,0.7);
}

.docResultWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
}

.document-list-title {
    text-align: left;
    font-size: 24px;
    margin: 0 0;
    font-family: 'AmazonEmber';
}