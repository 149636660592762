@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loading-spinner {
    width: 70px;
    height: 70px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #232f3e;; /* Amazon Blue */
    border-radius: 50%;
    animation: spinner 1s linear infinite;
}

.spinner-button {
    width: 30px;
    height: 30px;
    border-width: 3px;
    border-top-width: 3px;
}


.spinner-container {
    display: grid;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.btn-margin {
    margin-top: 0px;
}


