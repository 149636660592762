.section-title-container {
    display: flex;
    align-items: center;

    h2 {
        font-size: 20px;
        font-weight: 500;
        margin: 0 0;
        color: #232f3e;
        margin-right: 20px;
    }

    div {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
        border-radius: 50%;
    }

    div:hover {
        background-color: whitesmoke;
    }
    svg {
        cursor: pointer;
    }
}

.section-wrapper {
    margin-bottom: 12px;
}

.add-new-question {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #232f3e;
    border: 1px solid #232f3e;
    border-radius: 8px;
    padding: 4px 12px;
    margin-left: auto;
    background-color: white;
    margin-top: 20px;

    svg {
        margin-right: 12px;
    }
}

.add-new-question:hover {
    background-color: whitesmoke;
}