.review-cycle-container {
    display: flex;
    flex-direction: column;

    h2 {
        font-size: 22px;
        margin: 0 0;
        color: #232f3e;
    }

    p {
        font-size: 14px;
        margin: 0 0;
        margin-top: 12px;
        color: #232f3e;
    }
}

.review-cycle-content {
    display: flex;
    flex-direction: column;
}

.review-cycle-section {
    display: flex;
    justify-content: space-between;
}

.section-content {
    display: flex;
    flex-direction: column;
    width: 45%;
    margin-top: 20px;

    h2 {
        font-size: 16px;
        color: #232f3e;
        margin: 0 0;
        margin-bottom: 8px;
    }

    div {
        display: flex;
        justify-content: space-between;
    }
}

.review-input {
    border: 1px solid #bcbcbc;
    border-radius: 4px;
    padding: 8px;
    height: 39px;
    width: 100%;
}

.action-buttons {
    padding: 6px 20px;
    border-radius: 8px;
    font-size: 16px;
    border: 1px solid;
    height: 44px;
}