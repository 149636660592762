
.alert-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h4 {
        color: #232f3e;
        font-size: 20px;
        margin: 0 0;
        margin-top: 12px;
    }
}

.alert-buttons-container{
    display: flex;
    margin-top: 30px;

    button:first-child {
        margin-right: 20px;
        margin-left: 20px;
    }
}