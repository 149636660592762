.nda-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.nda-title-container {
    display: flex;
    width: 100%;
    background-color: whitesmoke;
    padding: 20px 20px;
    border-bottom: 1px solid #DCDCDC;

    h2 {
        font-size: 22px;
        font-family: -apple-system, BlinkMacSystemFont, 'AmazonEmber', 'Roboto';
        color: black;
        font-weight: 600;
        margin: 0 0;
    }
}

.nda-content-container {
    display: flex;
    flex-direction: column;
    padding: 0px 20px 0px 20px;
    margin-top: 20px;

    p {
        font-size: 14px;
        color: black;
        font-family: -apple-system, BlinkMacSystemFont, 'AmazonEmber', 'Roboto';
        font-weight: 400;
    }

    ul {
       strong{
        font-weight: 600;
       }
    }
}

.nda-acknowledge-container {
    display: flex;
    padding: 20px 20px;
    border-top: 1px solid #DCDCDC;
    align-items: center;

    p {
        font-size: 14px;
        font-family: -apple-system, BlinkMacSystemFont, 'AmazonEmber', 'Roboto';
        color: black;
        font-weight: 600;
        margin: 0 0;
    }

    button{
        background-color: #EC7211;
        color: white;
        padding: 5px 20px;
        margin-left: auto;
        border: none;
    }
}