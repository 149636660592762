.tag-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    button {
        border: solid 2px #232f3e;
        border-radius: 10px;
        margin: 10px 10px;
        padding: 15px;
        font-size: 11px;
        text-align: left;
        flex-grow: 1;
        width: 33%;
    }

    .filled {
        background-color: #232f3e;
        color: white;
    }
}

.meta-data-container{
    display: flex;
    flex-direction: column;

    h3 {
        font-size: 20px;
        color: #232f3e;
        margin: 0 0;
        margin-bottom: 12px;
    }
}

.meta-data-container:last-child {
    margin-top: 20px;
}