@font-face {
  font-family: 'AmazonEmber';
  src: url(./assets/fonts/AmazonEmber_W_Rg.woff2);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'AmazonEmber', 'Roboto',
    sans-serif;
  padding: 0 !important;
  overflow: auto !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

fieldset{
  margin: 0!important;
  padding: 0!important;
}

.css-6pamus-MuiPaper-root-MuiAppBar-root {
  background-color: #232f3e!important;
  display: flex!important;
  color: #fff!important;
  flex-direction: column!important;
    width: 100%!important;
    box-sizing: border-box!important;
}

.makeStyles-tabButton-2 {
  margin-left: 2rem!important;
}