.search-form {
    margin-top: 20px;
}

.search {
    display: flex;
    border: 1px solid #DCDCDC;
    padding: 12px 12px;
    align-items: center;
    width: 100%;
    input {
        font-size: 14px;
        color: #232f3e;
        margin: 0 0;
        border: none;
        outline: white;
        background-color: white;
        margin-left: 4px;
    }

    input::placeholder {
        color: #232f3e;
    }
}
