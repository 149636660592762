.buttons-container {
    display: flex;
    margin-top: auto;
}

.copy-button-container {
    display: flex;
    margin-left: 12px;
}

.no-left-margin {
    margin-left: 0px;
}

.copy-button {
    background-color: white;
    display: flex;
    border: 1px solid #232f3e;
    border-radius: 8px;
    color: #232f3e;
    align-items: center;
    align-self: flex-start;
    justify-content: center;
    padding: 4px 20px;

    svg {
        height: 18px;
        width: 18px;
        margin-right: 12px;
    }
}

.edit-button {
    background-color: #232f3e;
    display: flex;
    border: 1px solid #232f3e;
    border-radius: 8px;
    color: white;
    align-items: center;
    align-self: flex-start;
    justify-content: center;
    padding: 4px 20px;

    svg {
        margin-right: 12px;
        fill: white;
    }
}

.edit-button:hover {
    background-color: rgba(35,47,62,0.8);
}

.answer-container {
    display: flex;
    flex-direction: column;
    width: 85%;
    font-size: 14px;
    font-family: 'AmazonEmber';
    p {
        margin: 0 0;
        color: #232f3e;
    }
}

.answer-toolbar-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.toolbar-container {
    display: flex;
    flex-direction: column;
}

.icon-container {
    display: flex;
    padding: 6px 6px;
    cursor: pointer;
    position: relative;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.icon-copy-container {
    display: flex;
    align-items: center;
}

.icon-container:last-child {
    margin-bottom: 0px;
}

.icon-container:hover {
    background-color: whitesmoke;
}

.icon-title {
    font-size: 12px;
    color: #232f3e;
    text-align: center;
    width: auto;
    font-weight: 400;
    padding: 4px 10px;
    border-radius: 8px;
    position: absolute;
    background-color: whitesmoke;
    white-space: nowrap;
    top: 3px;
    left: 40px;
    visibility: hidden;
    transition: visibility 0.3s, opacity 0.3s;
    opacity: 0;
}

.icon-container:hover .icon-title{
    visibility: visible;
    display: flex;
    opacity: 1;
}


.accordion-summary-container {
    display: flex;
    align-items: center;
    width: 100%;

    svg:hover {
        cursor:grab;
    }
}

.accordion-metadata-container {
    display: flex;
    flex-direction: column;
    margin-left: 12px;

    h2 {
        font-size: 16px;
        font-weight: 500;
        margin: 0 0;
        color: #232f3e;
    }

    div {
        margin-top: 6px;
        display: flex;
        align-items: center;

        p {
            font-size: 12px;
            color: #232f3e;
            font-family: 'AmazonEmber';
            margin: 0 0;
            margin-left: 4px;
        }
    }
}

.accordion-assignee-container {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 20px;

    h2 {
        font-size: 14px;
        color: #232f3e;
        font-family: "AmazonEmber";
        font-weight: 600;
        margin: 0 0;
    }

    div {
        border: 1px solid #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        padding: 6px;
        background-color: rgba($color: #DF8244, $alpha: 1.0);
        position: relative;

        h4 {
            margin: 0 0;
            font-size: 16px;
            font-weight: 400;
            color: #fff
        }

        p {
            font-size: 12px;
            color: #232f3e;
            text-align: center;
            width: auto;
            font-weight: 400;
            padding: 4px 10px;
            border-radius: 8px;
            position: absolute;
            background-color: whitesmoke;
            white-space: nowrap;
            top: 33px;
            left: -10px;
            visibility: hidden;
            transition: visibility 0.3s, opacity 0.3s;
            opacity: 0;
        }
    }

    div:hover {
        p {
            visibility: visible;
            display: flex;
            opacity: 1;
        }
       
    }
}

.accordion-wrapper {
    margin-bottom: 12px;
}

.metadata-details {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-bottom: 12px;

    p {
        font-size: 12px;
        font-family: 'AmazonEmber';
        color: #232f3e;
        margin: 0 0;
    }
}

.reviewed-by {
    display: flex;
    margin-left: auto;
    margin-top: auto;
    p {
        font-size: 12px;
        font-family: 'AmazonEmber';
        color: #232f3e;
        margin: 0 0;
    }
   
}

.qa-settings-list {
    list-style: none;
    padding: 7px 0 7px 0;
    background: white;
    min-width: 200px;
    box-shadow: 0 3px 20px rgba(44, 52, 62, 0.30);
    margin-bottom: 0px;
}

.qa-settings-item {
    list-style: outside none none;

    padding: 8px 15px;
    margin: 0 0;
    cursor: pointer;
    position: relative;

    div {
        display: flex;
        align-items: center;

        h4 {
            margin: 0 0;
            font-size: 14px;
            font-weight: 400;
            color: #232f3e;
            margin-left: 12px;
        }
    }
}

.qa-settings-item:hover {
    background: whitesmoke;

    h4 {
        color: #456a97;
    }

    div {
        svg {
            fill: #456a97!important;
        }
    }
    
}

.delete {
    border-top: 1px solid #DCDCDC;
    margin-top: 6px;

    div {
        h4 {
            color: red;
        }
    }
}

.delete:hover {
    div {
        svg {
            fill: red!important
        }
        h4 {
            color: red;
        }
    }
}