.document-details-wrapper {
    display: flex;
    flex-direction: column;
}

.document-details-item {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    margin-bottom: 12px;

    h2 {
        font-size: 16px;
        font-weight: 400;
        margin: 0 0;
    }

    h4 {
        font-size: 16px;
        font-weight: 500;
        margin: 0 0;
        margin-top: 6px;
    }

    .bg-green {
        background-color: #7AD388;
    }

    .bg-red {
        background-color: #D37A7A;
    }

    .bg-yellow {
        background-color: #E5DF6B
    }
}

.document-details-item:last-child {
    margin-bottom: 0px;
}

.document-title-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    h2 {
        font-size: 24px;
        color: #232f3e;
        margin: 0 0;
    }
}

.edit-name-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 8px;
    border-radius: 50%;
    margin-left: 20px;
    cursor: pointer;
}

.edit-name-container:hover {
    background-color: whitesmoke;
}

.document-status-container {
    display: flex;
    align-items: center;
    margin-top: 6px;
}


.pill-select {
    width: auto;
}

.select-container {
    display: flex;
    flex-wrap: wrap;
}