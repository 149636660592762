
.snackbar-container {
    display: flex;
    background-color: #327335;
    padding: 2px 16px;
    border-radius: 4px;
    align-items: center;
    align-self: center;
    visibility: hidden;
    transition: visibility 0.3s, opacity 0.3s;
    opacity: 0;

    p {
        font-size: 14px;
        color: white;
        margin: 0 0;
        margin-left: 8px;
    }
}

.open {
    visibility: visible;
    opacity: 1;
}