.document-item-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid #DCDCDC;
    border-radius: 4px;
    padding: 16px 16px;
    margin-bottom: 12px;
    cursor: pointer;
}

.document-item-wrapper:hover {
    background-color: whitesmoke;
}

.document-item-title {
    font-size: 16px;
    font-weight: 600;
}

.document-item-metadata {
    font-size: 14px;
    font-weight: 400;
    margin: 0 0;
}

.document-item-tags-wrapper {
    display: flex;
    margin-top: 12px;
}

.document-item-tag-container {
    display: flex;
    align-items: center;
    padding: 6px 6px;
    border: 1px solid #DCDCDC;
    border-radius: 8px;
    background-color: rgba(220,220,220,0.16);
    margin-right: 8px;

    h3 {
        font-size: 12px;
        font-weight: 400;
        margin: 0 0;
        margin-left: 6px;
    }
}