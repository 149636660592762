@import "./constants.scss";

/* common */
.bold-text {
  font-weight: bold;
}

.display-inline {
  display: inline-block;
}

.small-bottom-margin {
  margin-bottom: 0.5rem;
}

.small-right-margin {
  margin-right: 1rem;
}

.action-link {
  cursor: pointer;
}

.center-align {
  text-align: center;
}

.left-align {
  text-align: left;
}

.small-left-padding {
  padding-left: 2rem;
}

/* search bar */

.search-bar {
  width: 75%!important;
  left: 20%;
}

.search-button {
  border-right: 1px solid $border-color !important;
  border-top: 1px solid $border-color !important;
  border-bottom: 1px solid $border-color !important;
  border-left: none !important;
  background-color: white !important;
}

.search-container {
  display: flex;
}

/* results panel */

.search-facet-container {
  width: 25%;
}

.search-result-container {
  width: 50%;
}

.results-section {
  color: black !important;
  position: relative;
}

.results-number {
  padding: 1.5rem;
  color: grey;
}

.empty-results {
  margin-top: 2rem;
}

.result-spinner {
  margin-top: 2rem;
}

.result-container {
  margin-bottom: 2rem;
}

.title {
  padding-bottom: 0.5rem;
  text-align: left;
}

.container-body {
  padding: 1rem;
  font-size: 15px;
  border-bottom: 1px solid $border-color;
}

.container-divider {
  border-top: 1px solid $border-color;
  height: 1px;
  padding: unset;
}

/* result footer */

.result-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  -ms-flex-item-align: start;
  align-self: flex-start;
  justify-self: flex-end;

  line-height: 1.2rem;
}

.footer-left-text {
  padding-top: 0.6rem;
  text-align: left;
  font-size: 12px;
  flex: 1;
}

.footer-right-text {
  padding-top: 0.6rem;
  text-align: right;
  font-size: 12px;
  flex: 1;
}

.file-format-divider-wrapper {
  float: right;
  padding-top: 0.1rem;
}

.file-format-divider {
  display: inline-block;
  border-right: 1px solid $border-color;
  height: 1rem;

  padding-right: 0.5rem;
  margin-right: 0.5rem;
}

.feedback-buttons {
  display: flex;
  -ms-flex-item-align: end;
  align-self: flex-end;
  margin-left: auto;
}

.feedback-buttons img {
  padding-left: 1rem;
  height: 3rem;
}

/* faq results */

.faq-title {
  padding: 1rem;
  font-size: 15px;
}

.faq-answer {
  padding-top: 0.5rem !important;
}

.expand-collapse {
  max-height: 30px;
  cursor: pointer;
  margin-right: 0.5rem;
  margin-top: -2px;
}

/* document results */

.document-results-section {
  padding-top: 2rem;
}

.card-title {
  color: grey;
  padding: 1rem 0 0 1rem;
}

.top-answer {
  color: yellow;
}

/* pagination */

.page-number {
  cursor: pointer;
  margin-right: 1.5rem;
}

.pagination-section {
  text-align: center;
  font-size: 15px;
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.arrow {
  max-height: 30px;
  margin-top: -2px;
  margin-right: 1rem;
}

/* Carousel */
.carousel-relative-wrapper {
  position: relative;

  .arrow-left {
    position: absolute;
    left: -1.5rem;
    top: calc(50% - 2rem);

    .arrow {
      padding: 0.5rem 0.5rem;
      margin-left: 0.5rem;

      svg {
        margin-top: -1rem;
        margin-left: 0.1rem;
      }
    }
  }

  .arrow-right {
    position: absolute;
    right: -2.5rem;
    top: calc(50% - 2rem);

    .arrow {
      padding: 0.5rem 0.75rem;
      margin-left: -3.5rem;

      svg {
        margin-top: -1rem;
      }
    }
  }

  .arrow {
    border: 1px solid #dcdfe4;
    border-radius: 50%;
    width: 2rem;
    height: 3rem;
    background: white;
    position: relative;
    cursor: pointer;

    &:hover svg * {
      stroke: #dcdfe4;
    }
  }

  .arrow {
    color: $blue !important;
  }

  .result-item {
    flex: 1 0 100%;
    flex-basis: 100%;
  }

  .carousel-container {
    display: flex;
  }

  .carousel-wrapper {
    width: 100%;
    overflow: hidden;

    .offscreen {
      margin-left: 2000rem;
      margin-left: 101vw;
    }
  }
}

.kendra-carousel-indicators {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: -1rem !important;
  padding-bottom: 0.5rem;
  padding-left: 0rem;

  li {
    &:nth-of-type(n + 2) {
      margin-left: 9px;
    }
  }
}

.kendra-carousel-indicator {
  display: block;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background-color: #b7b7b7;
  opacity: 0.15;
  transition: opacity 0.15s cubic-bezier(0.4, 0, 1, 1);

  &--active {
    opacity: 0.75;
  }
}

.contentLibrary {
  display: flex;
}

.filterPane {
  width: 20%;
  max-width: 240px;
  background-color: white;
  height: 100vw;
  padding-top: 1rem;
  border-right: solid 1px darkgrey;
  text-align: left;
  position: fixed;
  top: 0;
  z-index: 999;
}

.stickyFilters {
  position: fixed;
  left: 0rem;
  width: 240px;
  top: 4rem;
}

.results {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 0%;
}

.searchResults {
  width: 75%;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  margin-left: 20%;
  margin-right: auto;
}

.searchWrapper {
  display: flex;
  margin-top: 20px;
}

.searchHeader {
  padding: 1.5rem;
  margin-left: 240px;
  font-size: 24px;
  color: rgb(35,47,62);
  background-color: white;
  text-align: center;
  font-weight: 700;
  z-index: 999;
}

.coming-soon-filters{
  position: fixed;
  width: 240px;
  bottom: 3.5rem;
}

.answerWrapper {
  display: flex;
  min-height: 25vh;
}

.answer {
  padding: 10px;
  width: 95%;
  text-align: left;
}

.actions {
  float:right;
  display: flex;
}

.clickableIcon {
  margin: 0 10px;
}

.clickableIcon:hover, .clickableIcon:focus {
  cursor: pointer;
}

.MuiCard-root {
  margin-bottom: 1rem;
}

.resultExcerpt {
  margin-top: 25px;
  text-align: left;
}

.filter-header {
  background-color: #232f3e!important;
  color: white;
  padding: 10px;
  width: 100%;
  margin-bottom: 0;
}

.filter-header-title {
  font-size: 20px !important;
}

.full-width{
  width: 100%;
}

.filter-wrapper {
  padding-left: 1rem;
}