
.search-results-bar-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 20px;
}

.search-results-bar-text {
    font-size: 14px;
    margin: 0 0;
}

.search-results-sort-container {
    display: flex;
    align-items: center;
}

.search-results-sort {
    margin-left: 6px;
    border-radius: 4px;
    padding: 3px 6px;
}


