.text-input-container {
    display: flex;
    flex-direction: column;
    margin-top: 12px;

    h2 {
       font-size: 16px;
       color: #232F3E;
       font-weight: 500;
       margin: 0 0;
       margin-bottom: 12px; 
    }

    input {
        color: #232F3E;
        font-size: 14px;
        font-weight: 400;
        margin: 0 0;
        padding: 8px 8px;
        border: 1px solid #DCDCDC;
        outline: white;
        border-radius: 4px;
    }
}