

.review-dialog-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    h2 {
        margin: 0 0;
        color: #232f3e;
        font-size: 24px;
        margin-left: 12px;
    }
}

.assignee-container {
    display: flex;
    width: 100%;
    flex-direction: column;
}