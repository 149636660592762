.dialog-wrapper {
    display: flex;
    flex-direction: column;
    padding: 20px 20px;
    width: 100%;

    h2 {
        font-size: 20px;
        color: #232F3E;
        margin: 0 0;
        margin-bottom: 20px;
    }
}

.input-container {
    display: flex;
    flex-direction: column;

    h2 {
       font-size: 16px;
       color: #232F3E;
       font-weight: 500;
       margin: 0 0;
       margin-bottom: 12px; 
    }

    input {
        color: #232F3E;
        font-size: 14px;
        font-weight: 400;
        margin: 0 0;
        padding: 4px 4px;
        border: 1px solid #DCDCDC;
        outline: white;
        border-radius: 4px;
    }

    textarea {
        color: #424242;
        font-size: 14px;
        font-weight: 400;
        margin: 0 0;
        padding: 4px 4px;
        border: 1px solid #DCDCDC;
        outline: white;
        border-radius: 4px;
        min-height: 150px;
        max-height: 200px;
    }
}


.ql-container {
    min-height: 200px !important;
    max-height: 300px !important;
    overflow: hidden;
    overflow-y: auto;
    overflow-x: auto;
  }

.ql-editor {
    height: auto !important;
}


.input-container:last-child {
    margin-top: 20px;
}

.button-container {
    display: flex;
    margin-top: 20px;
    margin-left: auto;
}

.edit-buttons {
    padding: 6px 20px;
    border-radius: 8px;
    font-size: 16px;
    border: 1px solid;
    height: 44px;
    width: 104px;
}

.cancel {
    background-color: white;
    border-color:#232F3E;
    color: #232F3E;
}

.cancel:hover{
    background-color: whitesmoke!important;
}

.save {
    background-color: #232F3E;
    border-color: #232F3E;
    color: white;
    margin-left: 20px!important;
}

.save:hover{
    background-color: rgba(35,47,62,0.8)
}